import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../images/logo.svg"

const Outer = styled.div`
  position: fixed;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns:
    minmax(32px, auto) minmax(auto, ${props => props.theme.maxWidth})
    minmax(32px, auto);
  background: ${props => props.theme.oceanBlue};
  animation: fadeIn 1s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: 24px auto 24px;
  }
`
const Inner = styled.div`
  grid-column: 2;
  text-align: center;
  display: grid;
  align-content: center;
`
const Grid = styled.section`
  display: grid;
  grid-gap: 8px;
`
const LogoImg = styled.img`
  width: 240px;
  margin: 0 auto 64px auto;
`
const Title = styled.h1`
  display: none;
`
const ALink = styled.a`
  color: ${props => props.theme.oj};
  word-break: break-all;
`

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <Outer>
        <Inner>
          <LogoImg src={Logo} />
          <Title>Broadoaks Property Services</Title>
          <p>Get in touch:</p>
          <Grid>
            <ALink href="tel:+44115000000">Phone: 0115 000 000</ALink>
            <ALink href="mailto:jordan@broadoaks">
              Email: jordan@broadoakspropertyservices.com
            </ALink>
          </Grid>
        </Inner>
      </Outer>
    </Layout>
  )
}
